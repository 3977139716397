import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Hero1 from "../assets/images/Home/1.webp";
import Hero2 from "../assets/images/Home/2.webp";
import Hero3 from "../assets/images/Home/3.webp";
import Hero4 from "../assets/images/Home/4.webp";
import Hero5 from "../assets/images/Home/5.webp";
import Hero6 from "../assets/images/Home/6.webp";
import Footer1 from "../assets/images/Home/7.webp";




const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Navbar />
        <div className="page-title-area">
            <img className="imageStyle" src={Hero1} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Hero2} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Hero3} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Hero4} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Hero5} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Hero6} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Footer1} alt="image" />
        </div>
    </Layout>
)

export default IndexPage;
